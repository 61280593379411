import React from 'react';
import './style.css'
import { Link } from 'react-router-dom'



const Footer = () => {
  return (
    <>

      <div className='footer-container'>
        <div className='content-overlay'>

          <div className='footer-links'>
            <div className='footer-link-wrapper'>
              <div className='footer-link-items'>
                <h2>Resania Travel</h2>
                <Link to='/resania/how-it-works'>How it works</Link>
                <Link to='/resania/resania-articles/terms-and-conditions'>Terms and Conditions</Link>
                <Link to='/resania/about'>About Us</Link>
              </div>
              <div className='footer-link-items'>
                <h2>Contact Information</h2>
                <Link to='/resania/contactus'>Contact</Link>
                <Link to='/resania/help-plan'>Plan your Trip</Link>
                <Link to='/resania/resania-articles/privacy-policy'>Privacy Policy</Link>

              </div>
            </div>
            <div className='footer-link-wrapper'>
              <div className='footer-link-items'>
                <h2>Videos</h2>
                <Link to='/'>Watch Video</Link>
                <Link to='/'>Ambassadors</Link>
                <Link to='/'>Agency</Link>
              </div>
              <div className='footer-link-items'>
                <h2>Social Media</h2>
                <a href='https://instagram.com/resania_travel'>Instagram</a>
                <a href='https://www.facebook.com/Resania-Travel-110839561298586/'>Facebook</a>
                <a href='https:youtube.com/resania_travel'>Youtube</a>
              </div>
            </div>
          </div>
          <section className='social-media'>
            <div className='social-media-wrap'>
              <div className='footer-logo'>
                <Link to='/' className='social-logo'>
                  <img alt="resania travel ,safari adventure, climb kilimanjaro and zanzibar vacation , welcome"title="resania travel ,safari adventure, climb kilimanjaro and zanzibar vacation , welcome"
                    src='https://resania-static-space.ams3.digitaloceanspaces.com/resania-static/img/ResaniaLogos/resanial%20logo%20PNG-10.png'
                    width="170"
                    height="60"
                    alt="Resania logo"
                    style={{ padding: '0px' }}
                  />
                </Link>
              </div>
              <small className='website-rights'>RESANIA © 2021</small>
              <div className='social-icons'>
                <a
                  className='social-icon-link facebook'
                  href='https://www.facebook.com/Resania-Travel-110839561298586/'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Facebook'
                >
                  <i className='fab fa-facebook-f' />
                </a>
                <a
                  className='social-icon-link instagram'
                  href='https://instagram.com/resania_travel'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Instagram'
                >
                  <i className='fab fa-instagram' />
                </a>
                <a
                  className='social-icon-link youtube'
                  href='https://youtube.com/resania_travel'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='Youtube'
                >
                  <i className='fab fa-youtube' />
                </a>
                <a
                  className='social-icon-link whatsapp'
                  href='https://wa.me/message/FZEXUSJC5DJ2F1'
                  target='_blank'
                  rel='noreferrer'
                  aria-label='WhatsApp'
                >
                  <i className='fab fa-whatsapp' />
                </a>
              </div>

            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default Footer