import React from "react";
import { withRouter } from "react-router";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";

const BreadCrumbComponent = (props) => {
  const {
    history,
    location: { pathname },
  } = props;
  const pathnames = pathname.split("/").filter((x) => x);
  return (
    <div>
      <nav
        className="relative max-w-7xl mx-auto md:items-center md:justify-between pl-5 md:block hidden"
        aria-label="Breadcrumb"
      >
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a
                href="#"
                className="text-gray-200 hover:text-gray-500"
                onClick={() => history.push("/")}
              >
                <HomeIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.lenght - 1;

            return (
              <>
                <li key={name}>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-yellow-700"
                      aria-hidden="true"
                    />
                    <a
                      href={routeTo}
                      className="ml-4 text-sm font-medium text-gray-200 hover:text-gray-700 capitalize"
                    >
                      {name}
                    </a>
                  </div>
                </li>
              </>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default withRouter(BreadCrumbComponent)
