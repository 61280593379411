import React from 'react'
import {  ExternalLinkIcon } from "@heroicons/react/solid";


const CustomButton = ({heading, link}) => {
    return (
        <div className='flex justify-center'>
        <div className="inline-flex rounded-md shadow">
          <a
            href={link}
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-50 bg-customColor hover:bg-gray-50"
          >
            {heading}
            <ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </a>
        </div>
      </div>

    )
}

export default CustomButton
